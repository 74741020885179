.button-cta{
    cursor: pointer;
    height: 54px;
    transition: all 0.2s ease-in-out;
    width: 196px;
    .style{
        text-align: center;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        font-size: var(--font-size-m);
        font-style: normal;
        font-weight: 700;
    }
}

.header-button {
    background-color: var(--white);
    color: var(--eerie-black);
    left: calc(50.00% - 249px);
    opacity: 0;
    position: relative;
    margin-top: 60px;
    transform: translate(100px, 0);
    border: 5px solid #ffffff;
        &:hover {
            background-color: #0fceb0;
            border: 5px solid #ffffff;
        }
        &.animate-enter4 {
            animation: animate-enter4-frames 1.20s ease-in-out 0.89s 1 normal forwards;
            display: block;
            opacity: 0;
            transform: translate(100px, 0);
        }
        
}

.buttonWhite {
    background-color: var(--white);
    border: 5px solid var(--white);
    color: var(--eerie-black);
        &:hover {
        background-color: var(--main-color);
        border: 5px solid var(--white);
    }
    &.style-two{
        &:hover{
            background-color: var(--ebony-clay);
            border: 5px solid var(--main-color);
        }
        .style{
            &:hover{
                color: var(--white);
            }
        }
    }
    &.style-three{
        background-color: var(--main-color);
        border: 5px solid var(--main-color);
        color: var(--white);
        &:hover{
            background-color: var(--ebony-clay);
            border: 5px solid var(--main-color);
        }
    }
}

.rectangle-108-iU7JjP {
    background-color: var(--white);
    box-shadow: 0px 4px 4px #00000040;
    border: 5px solid #ffffff;
    cursor: pointer;
    height: 55px;
    left: 4px;
    mix-blend-mode: normal;
    pointer-events: auto;
    position: absolute;
    top: 409px;
    transition: all 0.2s ease;
    width: 196px;
  }
  
.rectangle-108-iU7JjP:hover {
  background-color: #0FCFB0;
  border: 5px solid #ffffff;
}