/*----------------------
    Team Style  
-------------------------*/
.rn-box-card,
.team-style-default {
    margin-top: 75px;
    .inner {
        background: linear-gradient(180deg,var(--color-lessdark) 20%,rgba(19,19,19,.4));
        -webkit-backdrop-filter: blur(4px) opacity(.95);
        backdrop-filter: blur(4px) opacity(.95);
        border-radius: var(--radius-big);

        .thumbnail {
            max-width: 150px;
            max-height: 150px;
            border-radius: 100%;
            border: 6px solid var(--color-lessdark);
            top: -75px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            min-width: 150px;
            min-height: 150px;
            overflow: hidden;
            img {
                border-radius: 100%;
            }
        }

        .content {
            text-align: center;
            padding: 20px;
            padding-top: 85px;
            padding-bottom: 35px;

            .title {
                font-size: 34px;
                line-height: 1.4;
                font-weight: 700;
                margin-bottom: 6px;
            }
            .subtitle {
                font-size: 20px;
                line-height: 1.4;
                margin-bottom: 15px;
                display: inline-block;
            }
            .team-form {
                display: block;
                font-size: 14px;
                margin-bottom: 12px;
                letter-spacing: 0.6px;
                font-style: italic;

                img {
                    padding-right: 10px;
                    max-height: 18px;
                }
            }
            .description {
                margin-bottom: 0;
                padding: 0 5%;
            }
        }

        .social-icon {
            li {
                a {
                    color: var(--color-body);
                }
            }
        }
    }

    &.border-gradient {
        .thumbnail {
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
            padding: 5px;
            border: 0 none;
        }
    }

    &.style-two {
        margin-top: 30px;
        .inner {
            padding-top: 35px;
            .thumbnail {
                top: 0;
                position: static;
                left: inherit;
                transform: none;
                min-width: 150px;
                min-height: 150px;
                margin: 0 auto;
            }
            .content {
                padding-top: 10px;
            }
        }
    }
}

/*---------------------
    Team Style Two  
-----------------------*/
.team-style-two {
    
        .leadership-p{
            color: var(--white);
            font-family: var(--main-font);
            font-size: 16px;
            line-height: 22px;
            font-style: normal;
            text-align: left;
            font-weight: 400;
        }
        @media(max-width: 800px){
            .leadership-p{
                font-size: 14px;
                line-height: 20px;
                padding-left: 55px;
                padding-right: 18px;
            }
        }
    
    .inner {
        .thumbnail {
            img {
                width: 300px;
                border-radius: var(--radius);
            }
            @media (max-width: 1500px) {
                img {
                    width: 296px;
                }
            }
        }
        .content {
            padding-top: 100px;
            .title {
                font-size: 16px;
                line-height: 1.4;
                font-weight: 600;
                margin-bottom: 0;
                color: var(--white);
                padding-top: 5px;

            }
            .under-title{
                font-size: 14px;
                font-weight: 400;
                color: var(--white);
            }
            .subtitle {
                font-size: 18px;
                line-height: 1.4;
                display: inline-block;
                color: var(--white);
                margin-top: 4px;
                margin-bottom: 0;
                opacity: 0;
                transform: translateX(30px);
                transition: transform .45s cubic-bezier(.16,.32,.26,.92),opacity .45s ease-out;
                transition-delay: .15s;
               
            }
            .team-form {
                display: block;
                font-size: 14px;
                letter-spacing: 0.6px;
                font-style: italic;
                margin-top: 11px;
                img {
                    padding-right: 10px;
                    max-height: 18px;
                }
            }
        }

}

.image-2-x0uKuI {

    cursor: pointer;
    height: 348px;
    left: 0px;
    mix-blend-mode: normal;
    object-fit: cover;
    pointer-events: auto;
    position: absolute;
    top: 0px;
    transition: all 0.2s ease;
    width: 322px;
  }
  
.image-2-x0uKuI:hover {
    transform: scale(0.9); 
  }
  
.green-box{
    cursor: pointer;
    background-color: var(--main-color);
    padding-top: 10px;
    border-radius: 10px;
    left: calc(50.00% - 150px);
    pointer-events: auto;
    position: absolute;
    top: 310px;
    transition: all 0.2s ease;
    width: 300px;
    backdrop-filter: blur(13px) brightness(100%);
    box-shadow: 0px 0px 8px #0000002e;
    &:hover{
      .subtitle{
      opacity: 1;
      transform: none;
      }
    }
  }
  .text-box {
    height: 76px;
    left: calc(50.00% - 133px);
    pointer-events: auto;
    position: absolute;
    top: -287px;
    transition: all 0.2s ease;
    width: 267px;
  }
}
.green-box::after{
    content: '';
    position: absolute;
    bottom: 36px;
    left: 0px;
    width: 300px;
    height: 341px;
    transform: scaleY(0);
    transform-origin: bottom center;
    -webkit-backdrop-filter: blur(13px) brightness(100%);
    backdrop-filter: blur(21px) brightness(100%) opacity(0.5);
    background-color: var(--caribbean-green-2);
    z-index: -1;
    transition: transform 0.3s;
}
.green-box:hover {
  transform: translate(0, -10px);
}
.green-box:hover::after {
  transform: translate(0, -10px);
  transform: scaleY(1);
}

