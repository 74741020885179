/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
// # Egi Mata - Frontend Engineer | 3D graphics  
:root { 
    --black: #000000;
    --black-2: #0000000f;
    --bon-jour: #dfdfdf;
    --caribbean-green: #0fcfb0;
    --ebony-clay: #263238;
    --eerie-black: #221e20;
    --shark: #252A30;
    --white: #ffffff;
  
    --main-color: #0FCFB0;
    --white: #ffffff;
    --header-color: #221E20;
    --dark: #252A30;
    --p-text: #263238;

    --alabaster: #fafafa;
    --caribbean-green-2: #0ecfb0cf;
    --mist-gray: #c4c4c4;
    --oxford-blue: #3a4a56;
    --quick-silver: #a6a6a6;
    
    --font-size-subheader:24px;
    --font-size-xl: 21px;
    --font-size-ll: 18px;
    --font-size-l: 16px;
    --font-size-m: 14px;
    --font-size-s: 12px;
    --font-size-xs: 10px;

    --bold: 800;
    --semi-bold: 600;
    --regular: 400;

    --main-font: "Montserrat", Helvetica;
    --body-font: "Montserrat", Helvetica;
  
  }

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
    font-family: var(--body-font);
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
}

.main-color{
    color: var(--main-color) !important;
}
.desktop-center-item {
    display: flex;
    align-items: center
}


/*input and button type focus outline disable*/

input::placeholder {
    color: #97A1B2;
  }
input:focus::placeholder {
    color: transparent;
  }
.comment-form-area {
	.single-input-wrap .single-input.textarea {
		margin-bottom: 10px;
	}
	.riyaqas-form-wrap .btn {
	    padding: 0 30px;
	}
}


code {
    color: #faa603;
}

.border-none {
    border: 0 !important
}
.gray-bg {
    background: #F5F8FF;
}
.blue-bg {
    background: #01358D;
}
.dark-bg {
    background: var(--dark);
}
.section-bg-1 {
    background-color: #f4f7fc;
}
.border-bottom-dashed {
    border-bottom: 1px dashed #8F98A8;
}

.bg-none {
    background: none;
    &:after {
        display: none;
    }
}
.z-1{
    z-index: 1;
}
.z-2{
    z-index: 2;
}
.z-3{
    z-index: 3;
}


.display-block{
    display: block !important;
}
.display-inline{
    display: -webkit-inline-box;
    -webkit-box-align: center;

}


/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/

.react-tabs__tab--selected {
    // background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
    /* visibility: hidden; */
  
  }
  
  .react-tabs__tab--selected div{
    background-color: #252A30 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .react-tabs__tab--selected p{
    color: #ffffff !important;
  }
  
  
  .react-tabs__tab-list{
    list-style:none;
  }


/*--------------------------------------------------------------
# Font Awesome
--------------------------------------------------------------*/

.fa{
    color:#ffffff;
}
.fa:hover {
    color:#0fcfb0;
}
  
.fa-angle-right:before{
    align-items: right;

    /* padding-left:60px; */
    color:#0FCFB0;
}
.fa-lg {
    float: right;
    line-height: 1;
    padding-right: 9px;
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.bg-gray {
    background: #F5F8FF;
}
.bg-custom{
    background-color: #f6f6f6;
  }
.bg-transparent{
    background-color: transparent !important;
}

// Padding Margin classes

.pd-top-120 {
    padding-top: 120px;
}
.pd-bottom-30{
    padding-bottom: 30px;
}
.pd-bottom-40{
    padding-bottom: 40px;
}
.pd-bottom-60{
    padding-bottom: 60px !important;
}
.pd-bottom-112{
    padding-bottom: 112px;
}
.pd-right-70{
    padding-right: 70px;
}
.padding-custom {
    padding: 0px 60px;
}
.pd-left-15{
    padding-left: 15px;
}
.pd-left-60{
    padding-left: 60px;
}
.pd-left-70{
    padding-left: 70px;
}
.pd-left-140{
    padding-left: 140px;
}
.pd-left-30{
    padding-left: 30px;
}
.pd-right-20{
    padding-right: 20px;
}
.pd-right-10{
    padding-right: 10px;
}

.pd-top-0{
    padding-top:0px !important;
}
.pd-top-30 {
    padding-top: 30px;
}
.pd-top-40 {
    padding-top: 40px;
}
.pd-top-50 {
    padding-top: 50px;
}
.pd-top-60 {
    padding-top: 60px;
}
.pd-top-80 {
    padding-top: 80px;
}
.pd-top-90 {
    padding-top: 90px;
}
.pd-top-100 {
    padding-top: 100px;
}
.pd-top-105 {
    padding-top: 105px;
}
.pd-top-110 {
    padding-top: 110px;
}
.pd-top-112 {
    padding-top: 112px;
}
.pd-top-115 {
    padding-top: 115px;
}
.pd-top-120 {
    padding-top: 120px;
}
.pd-top-135 {
    padding-top: 135px;
}
.pd-top-150 {
    padding-top: 150px;
}
.pd-top-170 {
    padding-top: 170px;
}
.pd-top-190 {
    padding-top: 190px;
}
.pd-top-200{
    padding-top: 200px;
}
.pd-bottom-50{
    padding-bottom: 50px;
}
.pd-bottom-65 {
    padding-bottom: 65px;
}
.pd-bottom-90 {
    padding-bottom: 90px;
}
.pd-bottom-100 {
    padding-bottom: 100px;
}
.pd-bottom-120 {
    padding-bottom: 120px;
}
.pd-default-120 {
    padding: 120px 0;
}
.pd-default-two {
    padding: 110px 0 120px 0;
}
.full-width{
    width: 100% !important;
}
.width-70{
    width: 70%;
}

.mg-bottom-50{
    margin-bottom: 50px !important;
    margin-top: 0 !important;
}
.mg-left-9{
    margin-left: 9px;
}
.mg-top-50{
    margin-top: 50px !important;
}
.mg-top-20{
    margin-top: 20px !important;
}
.mg-top-40 {
    margin-top: 40px;
}
.mg-top-45 {
    margin-top: 45px;
}
.mg-top-60 {
    margin-top: 60px;
}
.mg-top-65 {
    margin-top: 65px;
}
.mg-top-75 {
    margin-top: 75px;
}
.mg-top-80 {
    margin-top: 80px;
}
.mg-top-100 {
    margin-top: 100px;
}
.mg-top-105 {
    margin-top: 105px;
}
.mg-top-110 {
    margin-top: 110px;
}
.mg-top-120 {
    margin-top: 120px;
}
.mg-top-135 {
    margin-top: 135px;
}
.mg-top-170 {
    margin-top: 170px;
}
.mg-bottom-30 {
    margin-bottom: 30px;
}


/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.right{
    float: right !important;
}
.left{
    float: left !important;
}

.alignleft {
    float: left;
    clear: both;
    margin-right: 20px;
}

.alignright {
    float: right;
    clear: both;
    margin-left: 20px;
}

.aligncenter {
    clear: both;
    display: block;
    margin: 0 auto 1.75em;
}

.alignfull {
    margin: 1.5em 0;
    max-width: 100%;
}

.alignwide {
    max-width: 1100px;
}


/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/

.sticky {
    display: block;
}

.updated:not(.published) {
    display: none;
}

.blog-pagination ul li {
    display: inline-block;
}

.blog-pagination ul li+li {
    margin: 0 5px;
}

.blog-pagination {
    display: block;
    width: 100%;
}

.blog-pagination ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.blog-pagination ul li a,
.blog-pagination ul li span {
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid #e2e2e2;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    transition: .3s ease-in;
}

.blog-pagination ul li span.current,
.blog-pagination ul li a:hover {
    background-color: var(--main-color-one);
    color: #fff;
}


/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/


/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/


/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/

.gallery {
    margin-bottom: 1.5em;
}

.gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery-caption {
    display: block;
}


@media all and (max-width: 1500px){
    // :root {
    //     --body-font-size: 14px;
    //     --line-height30: 1.9;
    // }
}
@media all and (min-width: 1500px) {
    .custom-gutters-60>.col, 
    .custom-gutters-60>[class*=col-] {
        padding-right: 29px;
        padding-left: 29px;
    }
}
@media (min-width: 1920px){
    .pd-left-140-xl{
        padding-left: 140px;
    }
}
@media all and (min-width: 576px) {
    .custom-gutters-16>.col, 
    .custom-gutters-16>[class*=col-] {
        padding-right: 8px;
        padding-left: 8px;
    }
    .custom-gutters-18>.col, 
    .custom-gutters-18>[class*=col-] {
        padding-right: 9px;
        padding-left: 9px;
    }
    .custom-gutters-20>.col, 
    .custom-gutters-20>[class*=col-] {
        padding-right: 10px;
        padding-left: 10px;
    }
    .custom-gutters-28>.col, 
    .custom-gutters-28>[class*=col-] {
        padding-right: 14px;
        padding-left: 14px;
    }
}

@media (max-width: 780px){
    .mg-top-custom-footer{
        margin-top: 80px;
        top: 0 !important;
    }
    .d-none-mobile{
        display:none;
    }
    .pd-right-70{
        padding-right: 0px;
    }
    .pd-bottom-112{
        padding-bottom: 0px;
    }
    .pd-top-112 {
        padding-top: 10px;
    }
    .text-center{
        text-align: center !important;
    }
    .right{
        float: none !important;
    }
    .pd-bottom-100-mobile {
        padding-bottom: 0px !important;
    }
}