.features-section{
    position: relative;
    width: 100%;
    padding-bottom: 100px;
}

.platform-section{
    position: relative;
    padding-top: 250px;
    width: 100%;
    padding-bottom: 250px;
    p{
        font-weight: 700;
        font-size: var(--font-size-m);
        color: var(--ebony-clay);
        line-height: normal;
        position: relative;
        width: 257px;
    }
    img{
        float:right;
    }
}


/* ---------------------------------------
    Tabs
------------------------------------------*/

.tab-features {
    &.style-one{
        position: relative;
        top: -58px;
        width: 212px;
    }
    &.style-two{
        position: relative;
        text-align: center;
        cursor: pointer;
        background-color: var(--white);
        transition: all 0.2s ease-in-out;
        top: 27px;
        left: -4px;
        &:hover{
            transform: translate(0, -5px);
            background-color: #252A30;
        }
        .text-under{
            &:hover{
                color: var(--white);
            }
        }
    }
    .text-under{
        padding-bottom: 14px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 16px;
    }
}
.tab-border {
    &.style-one{
        background-color: var(--white);
        cursor: pointer;
        height: 116px;
        left: calc(50.00% - 106px);
        transition: all 0.2s ease-in-out;
        width: 212px;
        text-align: -webkit-center;
        align-content: center;
        &:hover {
            transform: translate(0, -5px);
            border-bottom: 6px solid var(--shark);
        }
    }
    .text-under {
        color: var(--ebony-clay);
        font-size: var(--font-size-l);
        font-weight: 700;
        padding-top: 16px;
        width: 100%;
    }
    .img-tab {
        cursor: pointer;
        opacity: 0;
        position: relative;
        top: 14px;
        width: 56px;
        transition: all 0.2s ease-in;
            &.animate-enter5 {
            animation: animate-enter5-frames 0.20s ease-in 0.00s 1 normal forwards;
            display: block;
            opacity: 0;
        }
        &:hover {
            transform: translate(0, -5px);
        }
    }
}

/* ---------------------------------------
    Homepage Section
------------------------------------------*/


// Feature Section Tabs
.bg-green{
    background-color: var(--main-color);
}
.bg-green-tilt{
    background-color: var(--main-color);
    &::before {
        content: "";
        background-color: var(--main-color);
        position: absolute;
        width: 110%;
        height: 59%;
        top: 68%;
        z-index: 0;
        transform: rotate(7deg);
    }
}
.check-list {
    margin-bottom: 15px;
    width: 83%;
    img {
        margin-right: 13px;
    }
    span {
        color: var(--ebony-clay);
        font-size: var(--font-size-m);
        font-weight: 600;
        line-height: normal;
    }
}
// IMG ANIMATION - Features Section
.img-animation{
    position: relative;
    top: -423px;
    left: calc(-28% - 5px);
    .texts p{
        color: var(--ebony-clay);
        font-size: var(--font-size-xs);
        font-weight: 600;
        line-height: normal;
        position: absolute;
        text-align: center;
        width: 86px;
    }
    .secure {
        left: calc(50.00% + 110px);
        top: 329px;
    }
    .multichain {
        left: calc(50.00% + 312px);
        top: 211px;
    }
    .api-connection{
        left: calc(50.00% + 524px);
        top: 332px;
    }
    .blockchain{
        left: calc(50.00% + 532px);
        top: 650px;
    }
    .cost-effective{
        left: calc(50.00% + 307px);
        top: 750px;
    }
    .fast-payment{
        left: calc(50.00% + 108px);
        top: 650px;
    }
    .white-image {
    background-color: transparent;
    display: block;
    height: 364px;
    left: calc(50.00% + 193px);
    opacity: 0;
    position: absolute;
    top: 298px;
    transform: rotate(90deg);
    width: 324px;
        &.animate-enter8 {
            animation: animate-enter8-frames 0.50s ease-in-out 0.00s 1 normal forwards;
            display: block;
            opacity: 0;
            transform: rotate(90deg);
        }
        .width {
            width: 324px;
        } 
        .backCircle {
            left: calc(50.00% - 96px);
            position: absolute;
            top: 86px;
        }
    }
    .middle-img {
        height: 106px;
        left: calc(50.00% + 302px);
        overflow: hidden;
        position: absolute;
        top: 427px;
        width: 106px; 
        .inner {
            height: 100.47%;
            left: -0.47%;
            position: absolute;
            opacity: 0;
            transform: rotate(40deg);
            top: -0.24%;
            width: 100.94%;
        }
        .inner.animate-enter9 {
        animation: animate-enter9-frames 2.00s ease-in-out 1.00s 1 normal forwards;
        display: block;
        opacity: 0;
        transform: rotate(40deg);
        }
        .logo {
            height: 72px;
            left: 28px;
            position: absolute;
            top: 17px;
            width: 52px;
        }
    }
    .arrow-1 {
        display: block;
        height: 124px;
        left: calc(50.00% + 183px);
        position: absolute;
        top: 319px;
        width: 136px;
        &.animate-enter13 {
            animation: animate-enter13-frames 0.70s ease-in-out 0.001s 1 normal forwards;
            display: block;
            transform: scale(0.67);
        }
    }
    .arrow-2 {
        display: block;
        height: 124px;
        left: calc(50.00% + 398px);
        position: absolute;
        top: 322px;
        width: 135px;
    }
    .arrow-3 {
        display: block;
        height: 112px;
        left: calc(50.00% + 396px);
        position: absolute;
        top: 514px;
        width: 154px;
    }
    .arrow-4 {
        display: block;
        height: 106px;
        left: calc(50.00% + 175px);
        position: absolute;
        top: 514px;
        width: 148px;
        &.animate-enter16 {
            animation: animate-enter16-frames 0.60s ease-in-out 0.001s 1 normal forwards;
            display: block;
            transform: translate(-25px, 0);
        }
    }
    .arrow-5 {
        display: block;
        height: 166px;
        left: calc(50.00% + 350px);
        position: absolute;
        top: 532px;
        width: 15px;
        &.animate-enter11 {
            animation: animate-enter11-frames 0.70s ease-in-out 0.001s 1 normal forwards;
            display: block;
            transform: translate(0, 25px);
        }
    }
    .arrow-6 {
        height: 184px;
        left: calc(50.00% + 346px);
        position: absolute;
        top: 242px;
        width: 15px;
        &.animate-enter13 {
            animation: animate-enter13-frames 0.70s ease-in-out 0.001s 1 normal forwards;
            display: block;
            transform: scale(0.67);
        }
    }
    .popout {
        animation: popout 1s ease;
        -webkit-animation: popout 1s ease;
    }
    .world-icons{
        .cheaper {
            height: 45px;
            left: calc(50.00% + 327px);
            mix-blend-mode: normal;
            object-fit: fill;
            position: absolute;
            top: 701px;
            width: 46px;
        }
        .secure {
            height: 37px;
            left: calc(50.00% + 137px);
            object-fit: fill;
            position: absolute;
            top: 290px;
            width: 32px;
        }
        .multichain {
            height: 38px;
            left: calc(50.00% + 336px);
            position: absolute;
            top: 169px;
            width: 38px;
        }
        .api {
            left: calc(50.00% + 548px);
            position: absolute;
            top: 306px;
        }
        .faster {
            height: 26px;
            left: calc(50.00% + 138px);
            position: absolute;
            top: 617px;
            width: 26px;
        }
        .blockchain {
            height: 34px;
            left: calc(50.00% + 558px);
            position: absolute;
            top: 615px;
            width: 34px;
        }

    }
}
  
// Other Section for now this comment
.client-area{
    position: relative;
    z-index: 2;
    width: 100%;
    padding-left: 68px;
    padding-right: 68px;
}


/* ---------------------------------------
    Platform Section
------------------------------------------*/

.vertical-line {
    position: relative;
    width: 1px;
}
.evo-img{
    float:none !important;
    margin-bottom: 19px;
}
.place-center{
    place-self: center;
}
@media(min-width: 1100px){
    .platform-section{
        p{
            &.text1{
                left: calc(50.00% - 16px);
            }
            &.text2{
                left: calc(50.00% - 223px);
            }
        }
    }
}
@media(min-width: 1600px){
    .platform-section{
        p{
            &.text1{
                left: calc(50.00% - -34px);
            }
            &.text2{
                left: calc(50.00% - 270px);
            }
        }
    }
}
@media(min-width: 1700px){
    .platform-section{
        p{
            &.text1{
                left: calc(50.00% - 41px);
            }
            &.text2{
                left: calc(50.00% - 197px);
            }
        }
    }
}
@media(min-width: 1900px){
    .platform-section{
        p{
            &.text1{
                left: calc(50.00% - -84px);
            }
            &.text2{
                left: calc(50.00% - 321px);
            }
        }
    }
}

/* ---------------------------------------
    Third Section
------------------------------------------*/
.third-section{
    padding-top:100px;
    padding-bottom: 50px;
    position: relative;
        &.bg-dark-one{
            background: var(--dark);
            &::before{
                content: "";
                background-color: var(--dark);
                position: absolute;
                width: 110%;
                height: 55%;
                top: -20%;
                z-index: 0;
                transform: rotate(-7deg);
            }
            &::after{
                content: "";
                background-color: var(--dark);
                position: absolute;
                width: 110%;
                height: 55%;
                top: 66%;
                z-index: 1;
                transform: rotate(7deg);
            }
        }
        &.bg-dark-two{
            background: var(--dark);
            &::before{
                content: "";
                background-color: var(--dark);
                position: absolute;
                width: 119%;
                height: 28%;
                top: -14%;
                z-index: -1;
                transform: rotate(-7deg);
                }
            }
        
    .section-title{
        p{
            color: var(--white);
        }
    }
    .platform-img{
        position: absolute;
        top: 10%;
        z-index: 2;
        img{
            width: 121%;
        }
    }
}


/* ---------------------------------------
    API Section
------------------------------------------*/

.api-img {
    .bg-url{
        background: url(../../images/homepage/square-style.svg) no-repeat;
        width: 110%;
        height: 100%;
        padding-bottom: 100px;
    }
    .inner-img{
        width: 615px;
        padding-top: 22px;
        padding-left: 22px;
        box-shadow: 21px 23px 20px 0px #00000029;
        &.animate-enter22{
            animation: animate-enter22-frames 0.20s ease-in-out 0.70s 1 normal forwards;
            display: block;
            opacity: 1;
        }
    }
}

/* ---------------------------------------
    Platform Part
------------------------------------------*/

.partners-section {
    position: relative;
  }
  
.bottom-image {
    height: 46%;
    position: absolute;
    top: 54%;
    width: 100%;
}
  
.partnerships-table {
    height: 660px;
    left: calc(50.00% - 496px);
    position: relative;
    top: 0px;
    width: 1000px;
    .bg-border{
        background-color: var(--shark);
        border: 5px solid var(--white);
        height: 660px;
    }
    .group-icons {
        left: 143px;
        position: absolute;
        top: 159px;
        width: 754px;
    }
    .partners-text {
        font-weight: 700;
        left: calc(50.00% - 78px);
        letter-spacing: 0.00px;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 56px;
        width: auto;
    }
    .partners-sub {
        left: calc(50.00% - 334px);
        font-size: var(--font-size-m);
        font-weight: 500;
        color: var(--white);
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 105px;
        width: 680px;
    } 
}

/* ---------------------------------------
    About Us Page
------------------------------------------*/

.years{
    h3{
        font-weight: 600;
        color: var(--ebony-clay)
    }
    img{
        margin-left: 119px;
        width: 81%;
        float: none;
    }
    p{
        font-weight: 400;
        width: 181px;
        left: 44px;
        text-align: start;
    }
    
}
.logo-img{
    width: 370px;
}


// Leadership
.leadership-footer{
    position: absolute !important;
}

@media (max-width: 1500px) {
    .logo-img{
        width: 282px;
    }
}

@media (max-width: 780px){
    .client-area{
        top: 5026px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .poland{
        width: 455px !important;
    }
    .evolution-section{
        position: relative;
        width: 100%;
        .barclays{
            width: 132px;
            padding-top: 30px;
        }
        .mit-delta{
            left: calc(50.00% - 120px);
            width: 195px;
        }
        .knick{
            width: 64px;
        }
        .mit-media-lab{
            width: 75px;
            padding-top: 30px;
        }
        .ripple-img{
            padding-top: 30px;
            width: 80px;
        }
        .coins{
            width: 78px;
            height: 22px;
            position: relative;
            top: 20px;
        }
        .decimal{
            width: 90px;
        }
        .celo{
            height: 46px;
            top: 10px;
            position: relative;
            width: 252px;
        }
        .european{
            height: 43px;
            width: 78px;
        }
        .en-logo{
            height: 25px;
            width: 87px;
        }
    }
    .mobile-history {
        background-color: var(--caribbean-green);
        min-width: 360px;
        overflow: hidden;
        overflow-x: hidden;
        position: relative;
        width: 100%;
    
        .white-wrapper{
            background-color: var(--white);
            position: relative;
        }
        .send-friends-evolution-P2zxrg {
            position: relative;
            text-align: center;
            padding-top: 50px;
        }
        .vertical-line-mobile {
            left: calc(50.00% - 145px);
            position: relative;
            top: 41px;
        }
        .line-dots {
            left: calc(50.00% - 153px);
            position: relative;
            bottom: 1268px;
        }
        .line-texts-logos {
            left: calc(50.00% - 105px);
            position: absolute;
            top: 138px;
            width: 285px;
            h6{
                font-weight: 600;
                color: var(--dark)
            }
            p{
                padding-top: 25px;
                line-height: normal;
                width:90%;
            }
        }
        .ellipse1{
            background-color: var(--ebony-clay);
            border-radius: 11px;
            height: 22px;
            left: 2px;
            position: absolute;
            width: 22px;
            top: 2px;
        }
        .ellipse2{
            background-color: var(--ebony-clay);
            border-radius: 11px;
            height: 22px;
            left: 2px;
            position: absolute;
            width: 22px;
            top: 250px;
        }
        .ellipse3{
            background-color: var(--ebony-clay);
            border-radius: 11px;
            height: 22px;
            left: 2px;
            position: absolute;
            width: 22px;
            top: 500px;
        }
        .ellipse4{
            background-color: var(--ebony-clay);
            border-radius: 11px;
            height: 22px;
            left: 2px;
            position: absolute;
            width: 22px;
            top: 750px;
        }
        .ellipse5{
            background-color: var(--ebony-clay);
            border-radius: 11px;
            height: 22px;
            left: 2px;
            position: absolute;
            width: 22px;
            top: 1000px;
        }
    }

    .third-section.bg-dark-two::before{
        top: -3%;
    }

}
@media (min-width: 1720px){
    .section-title{
        .title{
            padding-right: 250px;
        }
        .title-poland{
            padding-right: 0px !important;
        }
    }
    .section-title.style-two{
        .title{
            width:90%;
        }
    } 
}
    
