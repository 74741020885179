.footer-section {
    background-color: var(--eerie-black);
    height: auto;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
.footer-section-contact{
    top: 80.6%;
    height:auto;
  }
  
.footer-section-leadership{
    top: 90.6%;
  }
  
.footer-widget-wrap{
    padding-bottom: 10px;
}
.subscribe-section{
    background: var(--dark);
    background-repeat: no-repeat;
    padding: 60px 100px;
    margin-top: 45px;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0px 15px 20px 0px #00000023;
    -webkit-box-shadow: 0px 15px 20px 0px #00000023;
    .subs-title {
        line-height: normal;
        h4 {
            font-weight: 700;
            span {
                color: var(--white);
            }
        }
    }
} 
.left-img {
    position: absolute;
    top: 81px;
    left: -90px;
    object-fit: cover;
    width: 18.62%;
}
  
.footer-logo {
  margin-top: 25px;
  img {
    width: 182px;
    }
}
.footer-title{
    font-size: 20px;
    font-weight: 700;
    margin-top: 12px;
}
.list-style{
    list-style: none;
    color: var(--white);
    padding-top: 7px;
    padding-left: 0;
    li{
        margin-top: 8px;
    }
}
.list-link{
    text-decoration: none;
    color: var(--white);
    transition: all 0.2s ease;
    &:hover{
    color: var(--main-color);
    padding-left: 4px;
  }
}

.footer-copyright-area {
    padding-top: 20px;
    .left-text {
        text-align: left;
        width: 100%;
    }
}

.border-top-custom{
    position: relative;
}
.border-top-custom::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 4px;
    left: 0;
    background: linear-gradient(90deg, #ffffff00, #ffffff7a, #ffffff7a, #ffffff00);
}
  
  
  
  .socials {
    margin: 0;
    padding: 0;
  }
  .socials .list-style {
    text-align:right;
    padding-top: 0px;
  }
  .socials .list-style li{
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 0px;
  }
  .social-link{
    color: var(--white);
    transition: all 0.2s ease;
  }
  .social-link:hover{
    color: var(--main-color);
  }
  
  .address{
    font-size: 11px;
    font-family: 'Montserrat';
    color: white;
    font-weight: 400;
    padding-top: 17px;
    margin-bottom: -10px;
  }
  
  /* Mobile Footer */
  @media screen and (max-width: 780px) {
    .footer {
      background-color: var(--eerie-black);
      height: 3%;
      left: 0px;
      mix-blend-mode: normal;
      position: absolute;
      top: 97.05%;
      width: 100%;
    }
    .footer-homepage {
      height: 8.52%;
      top: 91.5%;
    }
    .footer-history{
      height: 9.2%;
      top: 90.8%;
    }
    .footer-contact{
      height: 23.52%;
      top: 76.48%;
    }
    .footer-leadership{
      height: 18.52%;
      top: 81.48%;
    }
    .footer-licence{
      top: 65.05%;
      height: 35%;
    }
    .footer-udaap{
      height: 488px;
      top: 152px;
      position:relative;
    }
    .address{
      font-size: 10px;
      position: absolute;
      top: 115px;
      text-align: center;
      left: -15px;
    }

    .left-img-footer {
      height: 100%;
      object-fit: cover;
      width: 15.83%;
      position: absolute;
    }
    .subscription-box {
      background-color: var(--shark);
      box-shadow: 0px 12px 14px #0000001a;
      padding: 30px 40px;
      margin-top: 25px;
      p{
        position: relative;
        text-align: center;
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        margin:0;
        span{
          color: var(--main-color);
        }
      }
    }
    .logo-footer {
      left: calc(50.00% - 129px);
      position: relative;
      img{
        position: relative;
        top: 15px;
        width: 76px;
      }
    }
    .contact-button{
      left: calc(50.00% - -13px);
      opacity: 0;
      top: -33px;
      background-color: var(--white);
      cursor: pointer;
      height: 41px;
      position: relative;
      transition: all 0.2s ease;
      width: 144px;
      &.animate-enter{
        animation: bp2-animate-enter7-frames 1.20s ease-in-out 0.89s 1 normal forwards;
        display: block;
        opacity: 0;
        transform: translate(100px, 0);
      }
      p{
        color: var(--eerie-black);
        font-size: 11px;
        font-weight: 700;
        margin: 0;
        text-align: center;
        padding-top: 5px;
      }
      &:hover{
        background-color: #0FCFB0;
        p{
          color: var(--white);
        }
      }
    }
  
    @keyframes bp2-animate-enter7-frames {
      from{opacity: 0;
      transform: translate(100px, 0);
      }
      to{opacity: 1;
      transform: translate(0,0);
      }
    }
  
    .frame-131-h0Texg {
      background-color: var(--eerie-black);
      height: 70px;
      left: 0px;
      mix-blend-mode: normal;
      position: relative;
      top: 0px;
      width: 88px;
    }
  
    .logo_menu-2-K8SRLr {
      background-color: transparent;
      height: 54px;
      left: 6px;
      mix-blend-mode: normal;
      object-fit: cover;
      position: absolute;
      top: 8px;
      width: 76px;
    }
  
    .middle-footer-gBjW7Z {
      background-color: transparent;
      height: 139px;
      left: calc(50.00% - 120px);
      position: absolute;
      top: 217px;
      width: 272px;
    }
  
    .company-k8XR3z {
      background-color: transparent;
      height: 17px;
      left: calc(50.00% - 136px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: center;
      top: 0px;
      width: auto;
    }
  
    .support-k8XR3z {
      background-color: transparent;
      height: 17px;
      left: calc(50.00% + 13px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: center;
      top: 0px;
      width: auto;
    }
  
    .history-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% - 136px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 34px;
      width: 57px;
    }
  
    .privacy-policy-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% + 13px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 34px;
      width: auto;
    }
  
    .leadership-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% - 136px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 57px;
      width: auto;
    }
  
    .state-licence-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% + 13px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 57px;
      width: 142px;
    }
  
    .careers-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% - 136px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 80px;
      width: auto;
    }
  
    .udaap-policy-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% + 13px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 80px;
      width: auto;
    }
  
    .contact-us-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% - 136px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 103px;
      width: auto;
    }
  
    .complaints-policy-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% + 13px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 103px;
      width: auto;
    }
  
    .file-a-complaint-k8XR3z {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% + 13px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 104px;
      width: auto;
    }
  
    .bottom-footer-gBjW7Z {
      background-color: transparent;
      height: 16.63%;
      left: 3.61%;
      position: absolute;
      top: 79.67%;
      width: 96.67%;
    }
  
    .line-1-o8Fgec {
      background-color: transparent;
      height: 1.23%;
      left: 10.89%;
      position: absolute;
      top: -0.62%;
      width: 73.71%;
    }
  
    .x-o8Fgec {
      background-color: transparent;
      height: auto;
      left: calc(50.00% - 48px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 16px;
      width: 8px;
    }
  
    .x-ag0KtK {
      background-color: transparent;
      height: auto;
      left: calc(50.00% + 16px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 16px;
      width: 8px;
    }
  
    .linked-in-o8Fgec {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% + 33px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 20px;
      width: 51px;
    }
  
    .twitter-o8Fgec {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% - 30px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 20px;
      width: 42px;
    }
  
    .facebook-o8Fgec {
      background-color: transparent;
      cursor: pointer;
      height: auto;
      left: calc(50.00% - 109px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: left;
      top: 20px;
      width: 57px;
    }
  
    .x2022-send-friend-all-rights-reserved-o8Fgec {
      background-color: transparent;
      height: auto;
      left: calc(50.00% - 103px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: center;
      top: 41px;
      width: auto;
    }
  
    .send-friend-is-licen-o8Fgec {
      background-color: transparent;
      height: auto;
      left: calc(50.00% - 174px);
      letter-spacing: 0.00px;
      line-height: normal;
      mix-blend-mode: normal;
      position: absolute;
      text-align: center;
      top: 59px;
      width: 334px;
    }
  
    .privacy-policy-yskUn0 {
      background-color: transparent;
      display: block;
      height: auto;
      left: 9.72%;
      letter-spacing: 0.00px;
      line-height: 22.6px;
      mix-blend-mode: normal;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 124px;
      transform: scale(1.1);
      white-space: nowrap;
      width: 42.50%;
    }
  
    .privacy-policy-yskUn0.bp2-animate-enter8 {
      animation: bp2-animate-enter8-frames 1.00s ease-in-out 0.65s 1 normal forwards;
      display: block;
      opacity: 0;
      transform: scale(1.1);
    }
  
    @keyframes bp2-animate-enter8-frames {
      from{opacity: 0;
    transform: scale(1.1);
    }
    to{opacity: 1;
    transform: scale(1);
    }
    }
  }

  @media (min-width: 768px) {
    .footer-section-contact {
      top: 75.6%;
      height: auto;
    }
    .footer-section-history {
      top: 85.2%;
    } 
    .footer-section-leadership {
      top: 90.6%;
    } 
    .footer-section-licence{
      top: 59%;
    }
    .footer-section-udaap{
      top: 130px;
    }
  }
  @media (min-width: 992px) {

    .footer-section-contact {
      top: 75.6%;
      height: auto;
    }
    .footer-section-history {
      top: 85.2%;
    } 
    .footer-section-leadership {
      top: 90.6%;
    } 
    .footer-section-udaap{
      top: 130px;
    }
  }
  @media (min-width: 1200px) {
    .footer-section-contact {
      top: 75.6%;
      height: auto;
    }
    .footer-section-history {
      top: 85.2%;
    } 
    .footer-section-leadership {
      top: 87.6%;
    } 
    .footer-section-udaap{
      top: 130px;
    }
  }
  @media (min-width: 1400px) {
    .footer-section {
      top: 88%;
    }
    .footer-section-contact {
      top: 76.4%;
      height: auto;
    } 
    .footer-section-history {
      top: 85.5%;
      height: auto;
    } 
    .footer-section-leadership {
      top: 90.5%;
      height: auto;
    } 
    .footer-section-udaap{
      top: 130px;
    }
  }
  @media (min-width: 1700px) {
    .footer-section-licence{
      top: 59%;
    }
    .footer-section-udaap{
      top: 130px;
    }
  }
  
  
  