.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
    
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 7px;
    list-style: none;
    text-align: center;
    width: auto;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    margin-top: 17px;
    height: 80px;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
  }
  
  .nav-links:hover {
    background-color: #1888ff00;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 600px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu-mobile.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #1888ff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      transition: 250ms;
    }
    

  
  }  

.header-zYNucu {
    background-color: var(--eerie-black);
    height: 116px;
    left: 0px;
    mix-blend-mode: normal;
    position: relative;
    top: 0px;
    z-index: 1;
    width: 100%;
  }
  
.top-header-num0gs {
    background-color: var(--caribbean-green);
    height: 35px;
    left: 0px;
    mix-blend-mode: normal;
    position: absolute;
    top: 0px;
    width: 100%;
  }
  
.facebook-icon-EMXBRh {
    background-color: transparent;
    cursor: pointer;
    height: 19px;
    left: 104px;
    mix-blend-mode: normal;
    position: absolute;
    top: 8px;
    width: 11px;
  }
  
.twitter-icon-EMXBRh {
    background-color: transparent;
    height: 16px;
    left: 132px;
    mix-blend-mode: normal;
    position: absolute;
    top: 10px;
    width: 19px;
  }
  
.linkedin-icon-EMXBRh {
    background-color: transparent;
    cursor: pointer;
    height: 18px;
    left: 167px;
    mix-blend-mode: normal;
    position: absolute;
    top: 8px;
    width: 19px;
  }
  
.login-button-default-num0gs {
    background-color: var(--eerie-black);
    border: 5px solid var(--caribbean-green);
    cursor: pointer;
    height: 61px;
    mix-blend-mode: normal;
    pointer-events: auto;
    position: absolute;
    right: 115px;
    top: 44px;
    transition: all 0.2s ease;
    width: 173px;
  }
  
.login-button-default-num0gs:hover {
    transform: scale(1.1);
  }
  
.login-Aoc3Ls {
    background-color: transparent;
    height: 27.87%;
    left: 36.42%;
    letter-spacing: 0.00px;
    line-height: normal;
    mix-blend-mode: normal;
    position: absolute;
    text-align: left;
    top: 36.07%;
    width: 27.17%;
  }
  
.menu-num0gs {
    background-color: transparent;
    height: 37px;
    left: 322px;
    mix-blend-mode: normal;
    position: absolute;
    top: 56px;
    width: 525px;
  }
  
.platform-kYUAzc {
    background-color: transparent;
    cursor: pointer;
    height: 45.95%;
    left: 1.90%;
    letter-spacing: 0.00px;
    line-height: normal;
    mix-blend-mode: normal;
    position: absolute;
    text-align: left;
    top: 27.03%;
    width: 15.61%;
  }
  
 .partners-kYUAzc {
    background-color: transparent;
    height: 45.95%;
    left: 27.41%;
    letter-spacing: 0.00px;
    line-height: normal;
    mix-blend-mode: normal;
    position: absolute;
    text-align: left;
    top: 27.03%;
    width: 15.23%;
  }
  
 .company-dropdown-kYUAzc {
    background-color: transparent;
    cursor: pointer;
    height: 47.95%;
    left: 71.54%;
    position: absolute;
    top: -102.97%;
    width: 0.95%;
  }
  
.vector-rLMcIQ {
    background-color: transparent;
    height: 52.94%;
    left: 84.83%;
    position: absolute;
    top: 28.88%;
    width: 14.85%;
  }
  
 .company-rLMcIQ {
    background-color: transparent;
    height: 100%;
    left: 0px;
    letter-spacing: 0.00px;
    line-height: normal;
    mix-blend-mode: normal;
    position: absolute;
    text-align: left;
    top: 0px;
    width: 81.65%;
  }
  
.contact-us-kYUAzc {
    background-color: transparent;
    height: auto;
    left: calc(50.00% + 158px);
    letter-spacing: 0.00px;
    line-height: normal;
    mix-blend-mode: normal;
    position: absolute;
    text-align: left;
    top: 10px;
    width: auto;
  }
  
.logo-menu-num0gs {
    background-color: var(--eerie-black);
    height: 109px;
    left: 73px;
    mix-blend-mode: normal;
    position: absolute;
    top: 35px;
    width: 145px;
  }
  
 .logo_menu-2-a4JE9e {
    background-color: transparent;
    height: 87px;
    left: 12px;
    mix-blend-mode: normal;
    position: absolute;
    top: 11px;
    width: 121px;
  }

  
 .header-qujsfd {
    background-color: var(--eerie-black);
    height: 63px;
    left: 0px;
    z-index: 2;
    mix-blend-mode: normal;
    position: absolute;
    top: 0px;
    width: 100%;
  }
  
 .hamburger-smneFN {
    background-color: transparent;
    cursor: pointer;
    height: 41.27%;
    left: 79.73%;
    overflow: hidden;
    position: absolute;
    top: 30.16%;
    width: 7.73%;
  }
  
 .vector-16-y8AOCp {
    background-color: transparent;
    height: 13px;
    left: 4px;
    position: absolute;
    top: 0px;
    width: 24px;
}
  
 .vector-17-y8AOCp {
    background-color: transparent;
    cursor: pointer;
    height: 13px;
    left: 4px;
    position: absolute;
    top: 6px;
    width: 24px;
}
  
 .vector-18-y8AOCp {
    background-color: transparent;
    height: 13px;
    left: 4px;
    position: absolute;
    top: 12px;
    width: 24px;
}
  
   .logo-smneFN {
    background-color: transparent;
    height: 68px;
    left: 46px;
    mix-blend-mode: normal;
    position: absolute;
    top: 12px;
    width: 88px;
  }
  
 .frame-131-oxZ3QX {
    background-color: var(--eerie-black);
    height: 68px;
    left: 0px;
    mix-blend-mode: normal;
    position: relative;
    top: 0px;
    width: 88px;
  }
  
 .logo_menu-2-ILoGHQ {
    background-color: transparent;
    height: 54px;
    left: 6px;
    mix-blend-mode: normal;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 76px;
  }
  