.dropdown-menu-nav {
    background: rgb(255, 255, 255);
    width: 200px;
    height: auto;
    position: absolute;
    top: 80px;
    border-radius: 2px;
    list-style: none;
    text-align: start;
    padding-left: 0px;
    transition: all 0.2s ease-in-out;
    z-index: 10;
}
.dropdown-menu-nav > li:hover {
    background: rgba(15,207,175,.35)
}
.dropdown-menu {
    background: rgb(255, 255, 255);
    width: 200px;
    height: auto;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    padding-left:0px;
    transition: all 0.2s ease-in-out;
    z-index: 10;
  }
  
  .dropdown-menu li {
    background: #ffffff;
    padding-left:20px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #0fcfaf5a;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: rgb(15, 15, 15)!important;
    padding: 16px 17px;
    padding-left: 33px;
}
  
  @media screen and (max-width: 960px) {
    /* .fa-caret-down {
      display: none;
    } */
  }
