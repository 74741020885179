:root { 
    --alabaster: #fafafa;
    --black: #000000;
    --black-2: #0000000f;
    --bon-jour: #dfdfdf;
    --caribbean-green: #0fcfb0;
    --caribbean-green-2: #0ecfb0cf;
    --ebony-clay: #263238;
    --eerie-black: #221e20;
    --mist-gray: #c4c4c4;
    --oxford-blue: #3a4a56;
    --quick-silver: #a6a6a6;
    --shark: #252a30;
    --white: #ffffff;
   
    --font-size-lh: 16px;
    --font-size-mh: 14px;
    --font-size-sh: 12px;
    --font-size-xlh: 21px;
    --font-size-xsh: 11px;
    --font-size-xxlh: 24px;
    --font-size-xxxlh: 36px;
   
    --main-font: "Montserrat", Helvetica;
    --font-family-neueradial-a-medium-regular: "neueRadial-A-Medium-Regular", Helvetica;
  }
  .montserrat-medium-white-14px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 500;
  }
  
  .montserrat-bold-white-14px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
  }
  .montserrat-bold-white-14px:hover {
    color: var(--main-color);
  }
  
  .montserrat-normal-ebony-clay-13px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  
  .montserrat-medium-white-11px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-xsh);
    font-style: normal;
    font-weight: 500;
  }
  
  .montserrat-semi-bold-white-16px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-lh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-normal-white-14px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 400;
  }
  
  .montserrat-semi-bold-ebony-clay-10px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-normal-quick-silver-16px {
    color: var(--quick-silver);
    font-family: var(--main-font);
    font-size: var(--font-size-lh);
    font-style: normal;
    font-weight: 400;
  }
  
  .montserrat-medium-white-16px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-lh);
    font-style: normal;
    font-weight: 500;
  }
  
  .montserrat-bold-ebony-clay-14px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-normal-ebony-clay-14px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 400;
  }
  
  .montserrat-bold-ebony-clay-12px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-sh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-white-32px {
    font-family: var(--main-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-white-32px-2 {
    color: var(--white);
    font-family: var(--main-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-semi-bold-white-24px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-xxlh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-bold-caribbean-green-24px {
    color: var(--caribbean-green);
    font-family: var(--main-font);
    font-size: var(--font-size-xxlh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-light-white-14px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 300;
  }
  
  .montserrat-bold-ebony-clay-16px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-lh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-extra-bold-white-36px {
    font-family: var(--main-font);
    font-size: var(--font-size-xxxlh);
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-semi-bold-ebony-clay-36px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-xxxlh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-bold-white-8px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
  }
  .montserrat-bold-white-8px1 {
    color: var(--white);
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-semi-bold-ebony-clay-24px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-xxlh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-normal-quick-silver-11px {
    color: var(--quick-silver);
    font-family: var(--main-font);
    font-size: var(--font-size-xsh);
    font-style: normal;
    font-weight: 400;
  }
  
  .montserrat-bold-caribbean-green-32px {
    color: var(--caribbean-green);
    font-family: var(--main-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-extra-bold-caribbean-green-36px {
    color: var(--caribbean-green);
    font-family: var(--main-font);
    font-size: var(--font-size-xxxlh);
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-extra-bold-white-36px-2 {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-xxxlh);
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-semi-bold-ebony-clay-14px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-bold-oxford-blue-32px {
    color: var(--oxford-blue);
    font-family: var(--main-font);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-extra-bold-white-21px {
    font-family: var(--main-font);
    font-size: var(--font-size-xlh);
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-extra-bold-ebony-clay-36px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-xxxlh);
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-semi-bold-black-24px {
    color: var(--black);
    font-family: var(--main-font);
    font-size: var(--font-size-xxlh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-extra-bold-caribbean-green-30px {
    color: var(--caribbean-green);
    font-family: var(--main-font);
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-semi-bold-ebony-clay-12px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: var(--font-size-sh);
    font-style: normal;
    font-weight: 600;
  }
  
  .montserrat-bold-white-14px-2 {
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-caribbean-green-14px {
    color: var(--caribbean-green);
    font-family: var(--main-font);
    font-size: var(--font-size-mh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-eerie-black-9px {
    color: var(--eerie-black);
    font-family: var(--main-font);
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-white-50px {
    font-family: var(--main-font);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-white-50px-2 {
    color: var(--white);
    font-family: var(--main-font);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-bold-caribbean-green-50px {
    color: var(--caribbean-green);
    font-family: var(--main-font);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-light-white-11px {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-xsh);
    font-style: normal;
    font-weight: 300;
  }
  
  .montserrat-bold-ebony-clay-18px {
    color: var(--ebony-clay);
    font-family: var(--main-font);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  

  
  .montserrat-bold-eerie-black-11px {
    color: var(--eerie-black);
    font-family: var(--main-font);
    font-size: var(--font-size-xsh);
    font-style: normal;
    font-weight: 700;
  }
  
  .montserrat-extra-bold-white-21px-2 {
    color: var(--white);
    font-family: var(--main-font);
    font-size: var(--font-size-xlh);
    font-style: normal;
    font-weight: 800;
  }
  
  .montserrat-semi-bold-black-64px {
    color: var(--black);
    font-family: var(--main-font);
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
  }
  
  .border-1px-mist-gray {
    border: 1px solid var(--mist-gray);
  }
  
  .border-5px-caribbean-green {
    border: 5px solid var(--caribbean-green);
  }
  
  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600,800");

.screen textarea:focus,
.screen input:focus {
  outline: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.overlay {
  display: none;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.overlay.animate-appear {
  animation: reveal 0.3s ease-in-out 1 normal forwards;
  display: block;
  opacity: 0;
}

.overlay.animate-disappear {
  animation: reveal 0.3s ease-in-out 1 reverse forwards;
  display: block;
  opacity: 1;
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

input:focus {
  outline: none;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.smart-layers-pointers,
.smart-layers-pointers * {
  pointer-events: auto;
  visibility: visible;
}

* {
  box-sizing: border-box;
}
