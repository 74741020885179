h1 {
    font-size: 64px;
}
h2 {
    font-size: 50px;
}
h3 {
    font-size: 36px;
}
h4 {
    font-size: 32px;
}
h5 {
    font-size: 24px;
}
h6 {
    font-size: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--main-color);
    font-family: var(--main-font);
}
p {
    color: var(--paragraph-color);
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin-bottom: 10px;
    line-height: var(--line-height30);
}
a {
    color: inherit;
    text-decoration: none;
    color: var(--paragraph-color);
}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: #0fcfb0;
}

pre {
    word-break: break-word;
}

a i {
    padding: 0 2px;
}


ol {
    counter-reset: counter;
    padding-left: 0;
}

ol li {
    list-style: none;
    margin-bottom: 1rem;
}

ol li:before {
    counter-increment: counter;
    content: counter(counter);
    font-weight: 500;
    margin-right: 10px;
}