:root {
    --main-color-one: #0FCFB0;
    --main-color-two: #F9556D;
    --secondary-color: #30373f;
    --heading-color: #272b2e;
    --paragraph-color: #5B6880;
    --montserrat-font: 'Montserrat', sans-serif;
    --body-font-size: 15px;
    --line-height30: 30px; 
    --bs-font-sans-serif: 'Montserrat', sans-serif !important;
  
  }



/**job-position-area **/
.single-job-list {
    background: #fff;
    -webkit-box-shadow: 0px 4px 20px #9398A11A;
    box-shadow: 0px 4px 20px #9398A11A;
    border-radius: 8px;
    padding: 22px 35px 22px 26px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .single-job-list:hover {
      -webkit-box-shadow: 0px 7px 30px #9398A11C;
      box-shadow: 0px 7px 30px #9398A11C; }
    .single-job-list img {
      width: 66px;
      height: 66px; }
    .single-job-list .media-body {
      border-left: 1px solid #EEEBF7;
      margin-left: 26.5px;
      padding-left: 25.5px; }
      .single-job-list .media-body h6 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.18px;
        color: #5B6880;
        display: inline-block;
        margin-bottom: 12px; }
      .single-job-list .media-body span {
        font-size: var(--body-font-size);
        line-height: var(--line-height30);
        font-weight: 400;
        letter-spacing: 0.3px;
        color: #5B6880;
        display: block; }
  
  .job-apply-btn {
    background: #FFFFFF;
    border: 1px solid #D6DAE1;
    border-radius: 4px;
    font-size: var(--body-font-size);
    letter-spacing: 0.3px;
    color: #97A1B3;
    height: 44px;
    line-height: 44px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0 30px;
    display: inline-block; }
    .job-apply-btn:hover {
      -webkit-box-shadow: 0px 7px 16px #01358D6B;
      box-shadow: 0px 7px 16px #01358D6B;
      background: #01358D;
      color: #fff;
      border: 1px solid #01358D; }
    .job-apply-btn:focus, .job-apply-btn:active {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0; }
  

/*----------------------------------------
  ## Section title
----------------------------------------*/
  .section-title-3 {
    max-width: 80%; }

    
/********* widget job details *********/
.widget-job-details {
  background: #fff;
  -webkit-box-shadow: 0px 2px 50px #97A1B229;
  box-shadow: 0px 2px 50px #97A1B229;
  border-radius: 8px;
  padding: 70px 38px; }
  .widget-job-details .widget-title {
    font-size: 22px;
    color: var(--main-color-one);
    font-weight: 500;
    letter-spacing: 0.2px;
    width: 211px;
    padding: 25px 0px;
    margin-bottom: 32px;
    text-align: center;
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #E4E7EB;
}
  .widget-job-details .single-job-details {
    margin-bottom: 25px; }
    .widget-job-details .single-job-details img {
      margin-top: 2px; }
    .widget-job-details .single-job-details .media-body {
      margin-left: 25.25px; }
      .widget-job-details .single-job-details .media-body h6 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #535764;
        margin-bottom: 10px; }
      .widget-job-details .single-job-details .media-body span {
        font-size: 15px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.3px;
        color: #97A1B3; }

.pd-top-112{
  padding-top:112px;
}

.client-slider-title {
  font-size: 36px;
  line-height: 62px;
  font-family: 'Montserrat';
  letter-spacing: 0.64px;
  color: #ffffff;
  font-weight: 700;
}
  .client-slider-title span {
    color: var(--main-color-one);
    font-weight: 600; }

    .owl-carousel{
      box-shadow: 0px 7px 20px 0px #00000024;
      padding: 20px;
      border-radius: 10px;
      }

/*----------------------------------------
    ## All form
------------------------------------------*/
.riyaqas-form-wrap .btn {
  height: 48px;
  line-height: 48px;
  padding: 0 60px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-top: 30px; }



.riyaqas-file-input-wrap {
  margin-top: 10px;
  height: 100%; }
  .riyaqas-file-input-wrap .riyaqas-file-input {
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    width: 100%; }
  .riyaqas-file-input-wrap label {
    cursor: pointer;
    border: 0;
    color: #fff;
    border-radius: 0;
    background: #32E2A9;
    height: 62px;
    line-height: 64px;
    text-align: center;
    padding: 0;
    cursor: cell; }
    .riyaqas-file-input-wrap label:after {
      display: none !important; }

.job-apply-area {
  -webkit-box-shadow: 0px 8px 30px #97A1B224;
  box-shadow: 0px 8px 30px #97A1B224;
  border-radius: 8px;
  padding: 120px 68px; }
  .job-apply-area .btn-blue {
    margin-top: 70px; }


/***** client-slider-2 *****/
.client-slider-2 {
  -webkit-box-shadow: 0px 10px 46px #97A1B212;
  box-shadow: 0px 10px 46px #97A1B212;
  border-radius: 10px;
  padding: 28px 0;
  background: #fff; }
  .client-slider-2 .client-slider-2-item {
    text-align: center; }
  .client-slider-2 .thumb img {
    width: auto;
    -webkit-filter: grayscale(10);
    filter: grayscale(10);
    opacity: 0.4;
    display: inherit;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in; }
  .client-slider-2 .thumb:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1; }
  .client-slider-2.style-two {
    -webkit-box-shadow: none;
    box-shadow: none; }


  